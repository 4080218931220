<template>
  <div class="dialog-wrap">
    <el-dialog
      :visible.sync="dialogStatus"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hidePayCode">
      <div class="header-wrap">
        <span class="img-close"></span>
        <span class="font-24-333-600">打开{{ payWay?'支付宝':'微信' }}扫码支付</span>
        <img class="img-close" src="@/assets/personalCenter/icon_close.png" @click="dialogStatus=false"/>
      </div>
      <div class="pay-code-wrap">
        <div class="pay-price-wrap">
          <span class="price-unit-other">￥</span>
          <span class="price-other">{{ selPack.price }}</span>
        </div>
        <img class="img-code" :src="payWay?alipayQrCodeImg:wxQrCodeImg" />
        <span class="font-24-666-400">{{ selPack.name }}</span>
      </div>
      <div class="pay-way-wrap">
        <div class="pay-way-label">
          <div class="pay-way-split"></div>
          <div class="font-20-cccccc-400">其他支付方式</div>
          <div class="pay-way-split"></div>
        </div>
        <div class="pay-way-item" @click="changePayWay">
          <img style="width: 36px;height: 36px;" :src="payWay ? require('@/assets/personalCenter/icon_wx_pay.svg') : require('@/assets/personalCenter/icon_zhifubao_pay.svg')"/>
          <span class="pay-way-txt font-24-226BF3-400" style="margin-left: 8px;">{{ payWay?'微信':'支付宝' }}支付</span>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {getPayQRCode,getPayStatus} from '@/api/vip.js'
import axios from 'axios'

export default {
  data(){
    return {
      dialogStatus: false,
      payWay: 0, // 微信：0， 支付宝：1,
      wxQrCodeImg: null, // 微信付款吗
      alipayQrCodeImg: null, // 支付宝付款码
      selPack:null,
      wxIntervalID:null,
      alipayIntervalID:null,
      cancelList:[]
    }
  },
  methods: {
    changePayWay(){
      this.payWay = Math.abs(this.payWay - 1)
      if((this.payWay == 1 && this.alipayQrCodeImg) || (this.payWay == 0 && this.wxQrCodeImg)) return
      this.changeQrCode()
    },
    resetStatus(){
      if(this.wxIntervalID) clearInterval(this.wxIntervalID)
      if(this.alipayIntervalID) clearInterval(this.alipayIntervalID)
      this.cancelList.forEach(element => {
        element()
      });
      this.payWay = 0
      this.alipayIntervalID = null
      this.wxIntervalID = null
      this.wxQrCodeImg = null
      this.alipayQrCodeImg = null
      this.cancelList.length = 0

    },
    hidePayCode(){
      this.resetStatus()
      this.dialogStatus = false
    },
    showPaycode(selPack){
      this.selPack = selPack
      this.changeQrCode()
      this.dialogStatus = true
    },
    changeQrCode(){
      const that = this
      let param = {id:this.selPack.id}
      let payWay = this.payWay
      if(payWay) param.pay_type = 'alipay'
      getPayQRCode(param).then(res=>{
        let objData = res.data.result
        if(this.payWay) that.alipayQrCodeImg = objData.base64
        else that.wxQrCodeImg = objData.base64
        that.checkOrderStatus(objData.oid, payWay)
      })
    },
    checkOrderStatus(oid, payWay){
      if(payWay && !this.alipayIntervalID) {
        this.alipayIntervalID = this.generateInterVal(oid, payWay)
      } else if(payWay == 0 && !this.wxIntervalID){
        this.wxIntervalID = this.generateInterVal(oid, payWay)
      }
    },
    generateInterVal(oid, payWay){
      let that = this
      const CancelToken = axios.CancelToken;
      return setInterval(()=>{
        getPayStatus({oid},
          {
            cancelToken: new CancelToken(function executor(c) {
            that.cancelList.push(c)
          })}).then(res=>{
            if(res.data.status == 2){
              clearInterval(payWay?that.alipayIntervalID : that.wxIntervalID)
              that.$emit('paySuccessEvent')
              that.hidePayCode()
            }
        })
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
img[src=""],img:not([src]){
    opacity:0;
}
::v-deep .el-dialog{
  width: 480px;
  background: #FFF;
  border-radius: 32px;
}
::v-deep .el-dialog__header,
::v-deep .el-dialog__footer{
  display: none;
}
::v-deep .el-dialog__body{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;
}
.header-wrap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .img-close{
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }
}
.pay-code-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-price-wrap{
    display: flex;
    align-items: baseline;
    .price-unit-other{
      color: #F44336;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }
    .price-other{
      text-align: center;
      color: #F44336;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 54px; /* 150% */
    }
  }
  .img-code{
    width: 240px;
    height: 240px;
    background: #fff;
  }
}
.pay-way-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  .pay-way-label{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .pay-way-split{
      flex: 1 0 0;
      height: 1px;
      background: var(--neutral-color-eeeeee, #EEE);
    }
  }
  .pay-way-item{
    &:hover{
      cursor: pointer;
    }
  }
}
.pay-way-wrap:hover > .pay-way-txt{
  color:red;
}
.font-24-333-600{
  color: #333;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
}
.font-24-666-400{
  color: #666;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
.font-24-226BF3-400{
  text-align: center;
  color: #226BF3;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
.font-20-cccccc-400{
  color: #cccccc;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
</style>