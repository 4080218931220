
import { instance } from '@/utils/http.js'

// 获取用户详情
export const getUserInfo = function () {
  return instance({
    url: '/api/v1/user/get_user',
    method: 'get',
  })
}

// 修改用户信息
export const editUserInfo = function (data) {
  return instance({
    url: '/api/v1/user/edit_user',
    method: 'post',
    data
  })
}

// 修改密码
export const resetPwd = function (data) {
  return instance({
    url: '/api/v1/user/edit_pwd',
    method: 'post',
    data
  })
}

// 得分率情况
export const getScoreDetail = function () {
  return instance({
    url: '/api/v1/user/score_detail',
    method: 'get'
  })
}

// 成长值
export const getGrowData = function () {
  return instance({
    url: '/api/v1/user/max_score_detail',
    method: 'get'
  })
}
