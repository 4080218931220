import { instance } from '@/utils/http.js'

// 会员信息
export const getVipInfo = function (params) {
  return instance({
    url: '/api/v1/user/user_info',
    method: 'get',
    params
  })
}

// 会员套餐列表
export const getVipPack = function (params) {
  return instance({
    url: '/api/v1/product/get_product',
    method: 'get',
    params
  })
}

//付款二维码
export const getPayQRCode = function (data) {
  return instance({
    url: '/api/v1/pay/wechat_pay',
    method: 'post',
    data
  })
}

// 付款后轮询查看订单状态
export const getPayStatus = function (params, cancelObj) {
  return instance({
    url: '/api/v1/pay/check_order',
    cancelObj,
    method: 'get',
    params
  })
}

// 积分信息
export const getScoreInfo = function (params) {
  return instance({
    url: '/api/v1/user/invite_detail',
    method: 'get',
    params
  })
}